import React, { useEffect } from "react"
export default function Banner() {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "//remisspig.com/d821bb69566a035b4f19a479c1f2d5e4/invoke.js"
    document.head.appendChild(script)
    return () => {
      script.remove()
    }
  }, [])

  return (
    <div className="text-center">
      <div id="container-d821bb69566a035b4f19a479c1f2d5e4"></div>
    </div>
  )
}
